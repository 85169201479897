import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  ListItem,
  Stack,
  UnorderedList,
  chakra,
} from '@chakra-ui/react';
import React, { useContext, useReducer, useState } from 'react';
import { Store } from '../../../utils/Store';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../../../utils/Utils';
import { useNavigate } from 'react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_PROFILE_PASS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };

    default:
      return state;
  }
};
const ChangePassword = ({ api, border }) => {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [{ loadingUpdate }, dispatch] = useReducer(reducer, {
    loadingUpdate: false,
  });

  const handleChangePass = async (e) => {
    e.preventDefault();
    if (password !== newPassword) {
      if (confirmPassword === newPassword) {
        try {
          dispatch({ type: 'UPDATE_REQUEST' });
          const { data } = await axios.put(
            `${api}/api/users/change-password`,
            { newPassword },
            { headers: { authorization: `Bearer ${userInfo.token}` } }
          );
          dispatch({ type: 'UPDATE_PROFILE_DATA' });
          ctxDispatch({ type: 'USER_SIGNOUT' });
          localStorage.removeItem('userInfo');
          toast.success(
            'Datele au fost actualizate cu succes. Te rugăm să te autentifici.'
          );
          navigate('/logare');
        } catch (error) {
          dispatch({ type: 'UPDATE_FAIL' });
          toast.error(getError(error));
        }
      } else {
        toast.error('Parolele nu sunt identice');
      }
    } else {
      toast.error('Nu poți folosi aceeași parolă');
    }
  };
  return (
    <Stack
      id={'changePassword'}
      w={'full'}
      borderRadius={'1.5rem'}
      bg={'brand.100'}
      boxShadow={'0 0 32px rgba(0,0,0,0.3)'}
      px={'3rem'}
      py={'2rem'}
      gap={'3rem'}
    >
      <Heading as={'h2'} fontSize={'1.75rem'}>
        Schimbă-ți parola
      </Heading>
      <chakra.form onSubmit={handleChangePass}>
        <Stack gap={'2rem'}>
          <Stack gap={'1.5rem'}>
            <FormControl isRequired>
              <FormLabel>Parola curentă:</FormLabel>
              <Input
                borderColor={border}
                borderRadius={'1rem'}
                type={'password'}
                placeholder="Parola curentă"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Parola nouă:</FormLabel>
              <Input
                borderColor={border}
                borderRadius={'1rem'}
                type={'password'}
                placeholder="Parola nouă"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Confirmă parola nouă:</FormLabel>
              <Input
                borderColor={border}
                borderRadius={'1rem'}
                type={'password'}
                placeholder="Confirmă parola nouă"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
          </Stack>

          <HStack
            flexWrap={'wrap'}
            w={'full'}
            justifyContent={'space-between'}
            alignItems={'flex-end'}
          >
            <Stack>
              <Heading fontSize={'1.25rem'} as={'h3'}>
                Cerințe parolă
              </Heading>
              <Heading color={'rgb(45, 45, 46)'} fontSize={'.95rem'} as={'h4'}>
                Vă rugăm să urmați cerințele de mai jos pentru a detine o parolă
                sigură
              </Heading>
              <UnorderedList color={'rgb(45, 45, 46)'}>
                <ListItem>Un caracter special</ListItem>
                <ListItem>
                  Un număr (este recomandat sa fie două numere)
                </ListItem>
                <ListItem>Minim 8 caractere</ListItem>
              </UnorderedList>
            </Stack>
            <Stack>
              <Button
                type="submit"
                bg={'brand.400'}
                borderRadius={'xl'}
                _active={'none'}
                _hover={{ transform: 'translateY(-5px)' }}
                boxShadow={'0px 5px 24px rgba(0, 0, 0, 0.3)'}
                fontWeight={'bold'}
                color={'textDark'}
              >
                Schimbă parola
              </Button>
            </Stack>
          </HStack>
        </Stack>
      </chakra.form>
    </Stack>
  );
};

export default ChangePassword;
