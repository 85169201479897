import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getError } from '../../utils/Utils';
import { Store } from '../../utils/Store';

const PfForm = ({ listaJudete, api }) => {
  const navigate = useNavigate();
  const [adresa, setAdresa] = useState('');
  const [oras, setOras] = useState('');
  const [judet, setJudet] = useState('');

  const [listaOrase, setListaOrase] = useState([]);

  const { state } = useContext(Store);
  const { userInfo } = state;

  const oraseHandler = (item) => {
    setJudet(item.nume);
    const oraseRomania = async () => {
      const { data } = await axios.get(
        `https://roloca.coldfuse.io/orase/${item.auto}`
      );
      setListaOrase(data);
    };
    oraseRomania();
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        `${api}/api/users/date-facturare-pf`,
        {
          judet,
          oras,
          adresa,
        },
        { headers: { authorization: `Bearer ${userInfo.token}` } }
      );
      navigate('/register/pachete');
    } catch (error) {
      toast.error(getError(error));
    }
    console.log('send data to user info');
  };
  return (
    <Stack w={'full'} maxW={'44rem'}>
      <form onSubmit={submitHandler}>
        <Stack gap={'1rem'}>
          <HStack w={'full'}>
            <FormControl>
              <FormLabel>Judet:</FormLabel>
              <Select
                borderColor={'#030303'}
                _hover={'none'}
                placeholder="Alegeti judetul..."
                onChange={(e) => {
                  const selectedItem = listaJudete.find(
                    (item) => item.nume === e.target.value
                  );
                  oraseHandler(selectedItem);
                }}
              >
                {listaJudete.map((item, index) => (
                  <option key={index} value={item.nume}>
                    {item.nume}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Localitatea:</FormLabel>
              <Select
                borderRadius={'.5rem'}
                borderColor={'#030303'}
                _hover={'none'}
                placeholder="Alegeti orasul..."
                isDisabled={judet === ''}
                onChange={(e) => setOras(e.target.value)}
              >
                {listaOrase.map((item, index) => (
                  <option key={index} value={item.nume}>
                    {item.nume}
                  </option>
                ))}
              </Select>
            </FormControl>
          </HStack>
          <FormControl>
            <FormLabel>Adresa:</FormLabel>
            <Input
              borderRadius={'.5rem'}
              borderColor={'#030303'}
              _hover={'none'}
              type="text"
              onChange={(e) => setAdresa(e.target.value)}
            />
          </FormControl>
          <Button
            w={'fit-content'}
            borderRadius={'2rem'}
            type="submit"
            bg={'brand.201'}
            color={'#fff'}
            _hover={'none'}
            _active={'none'}
          >
            Continua
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default PfForm;
