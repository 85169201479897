import {
  Box,
  HStack,
  IconButton,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import React, { useContext, useEffect, useReducer } from 'react';
import { MessageBox, PageTitle } from '../../components';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../../utils/Utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Store } from '../../utils/Store';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        articole: action.payload.articole,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };
    case 'DELETE_REQUEST':
      return { ...state, loadingDelete: true, successDelete: false };
    case 'DELETE_SUCCESS':
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case 'DELETE_FAIL':
      return { ...state, loadingDelete: false, successDelete: false };

    case 'DELETE_RESET':
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

const ListaArticole = ({ api }) => {
  const navigate = useNavigate();
  const [{ error, articole, pages, successDelete }, dispatch] = useReducer(
    reducer,
    {
      loading: false,
      error: '',
      articole: [],
    }
  );

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;

  const { state } = useContext(Store);
  const { userInfo } = state;
  const idUser = userInfo._id;

  const deleteHandler = async (articol) => {
    if (window.confirm('Are you sure to delete?')) {
      try {
        await axios.delete(`${api}/api/articol/delete/${articol}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        toast.success('Articol șters cu succes');
        dispatch({ type: 'DELETE_SUCCESS' });
      } catch (err) {
        toast.error(getError(error));
        dispatch({
          type: 'DELETE_FAIL',
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(
          `${api}/api/articol/user/articole?page=${page}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    if (successDelete) {
      dispatch({ type: 'DELETE_RESET' });
    } else {
      fetchData();
    }
  }, [api, successDelete, userInfo.token, page, idUser]);

  const tableHead = [
    'coperta',
    'titlu',
    'categorie',
    'redactor',
    'data actualizare',
    'data publicare',
    'vizualizari',
    'acțiuni',
  ];
  return (
    <Stack
      w={'full'}
      maxW={'100rem'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={'3rem'}
      px={{ base: '1rem', lg: '3rem' }}
      pb={'2rem'}
    >
      <Helmet>
        <title>VOX Press - Articole Utilizator</title>
      </Helmet>
      <PageTitle title={'Articolele mele'} />

      {articole.length > 0 ? (
        <TableContainer
          className="tableContainer"
          w={'full'}
          display={'flex'}
          flexDir={'column'}
          gap={'2rem'}
        >
          <Table variant={'striped'} colorScheme="blackAlpha">
            <Thead>
              <Tr>
                {tableHead.map((item, index) => (
                  <Th key={index}>{item}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {articole.map((articol) => (
                <Tr key={articol._id}>
                  <Td>
                    <Image
                      w={'80px'}
                      src={`${articol.pozaCoperta[0].src}`}
                      alt={articol.descriereCoperta}
                    />
                  </Td>
                  <Td>
                    <Text
                      w={'300px'}
                      overflow={'hidden'}
                      textOverflow={'ellipsis'}
                      whiteSpace={'nowrap'}
                    >
                      {articol.titlu}
                    </Text>
                  </Td>
                  <Td>{articol.categorie} </Td>
                  <Td>{articol.redactor} </Td>

                  <Td>{articol.dataActualizare}</Td>
                  <Td>{articol.dataPostare}</Td>
                  <Td isNumeric>
                    <Box
                      bg={'#797979'}
                      borderRadius={'1.5rem'}
                      color={'brand.100'}
                      fontWeight={'bold'}
                      px={'1.25rem'}
                      py={'1rem'}
                      textAlign={'center'}
                    >
                      {articol.views}
                    </Box>
                  </Td>
                  <Td>
                    <HStack gap={'.5rem'}>
                      <Tooltip label={'Editează articolul'}>
                        <IconButton
                          bg={'brand.200'}
                          color={'#f3f3f3'}
                          fontSize={'1.5rem'}
                          _hover={{ bg: 'brand.201' }}
                          onClick={() =>
                            navigate(`/user/articol/${articol._id}`)
                          }
                          icon={<BiEdit />}
                        />
                      </Tooltip>

                      <Tooltip label={'Șterge articolul'}>
                        <IconButton
                          bg={'red'}
                          color={'#f3f3f3'}
                          fontSize={'1.5rem'}
                          _hover={{ bg: 'darkred' }}
                          onClick={() => deleteHandler(articol._id)}
                          icon={<BiTrash />}
                        />
                      </Tooltip>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <MessageBox>Momentan nu aveți articole postate</MessageBox>
      )}
      <HStack w="full" gap={'.5rem'} flexWrap={'wrap'}>
        {[...Array(pages).keys()].map((x) => (
          <Link
            className={x + 1 === Number(page) ? 'btn text-bold' : 'btn'}
            key={x + 1}
            to={`/user/articole?page=${x + 1}`}
          >
            <Box
              border={'1px solid'}
              borderColor={x + 1 === Number(page) ? 'brand.100' : 'brand.300'}
              color={x + 1 === Number(page) ? 'brand.100' : 'brand.300'}
              bg={x + 1 === Number(page) ? 'brand.300' : 'brand.100'}
              boxSize={'25px'}
              gap={'2rem'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              borderRadius={'0.3rem'}
            >
              {x + 1}
            </Box>
          </Link>
        ))}
      </HStack>
    </Stack>
  );
};

export default ListaArticole;
