import { useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './utils/ScrollToTop';
import {
  ArticolNou,
  CreazaArticol,
  EditArticol,
  EditareArticol,
  Home,
  ListaArticole,
  ListaArticoleAdmin,
  ListaUtilizatori,
  Login,
  Pachete,
  PacheteReg,
  PaymentSuccess,
  ProfilUser,
  Register,
  Ziare,
  ZiareReg,
  ZiareUser,
} from './pages';
import {
  AdminRoute,
  Meniu,
  Navbar,
  PayedRoute,
  Sidebar,
  UserRoute,
} from './components';
import { HStack, Stack } from '@chakra-ui/react';
import { Store } from './utils/Store';
import BillingData from './pages/registerSteps/BillingData';

function App() {
  const dev = true;
  const api = dev ? 'https://api.voxpress.ro' : 'http://localhost:5000';
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const { state } = useContext(Store);
  const { userInfo } = state;
  return (
    <Router>
      <ToastContainer position="bottom-center" limit={1} />
      <ScrollToTop />

      <HStack
        w={'full'}
        bg={'brand.400'}
        alignItems={'flex-start'}
        className="body"
        position={'relative'}
        overflow={'hidden'}
      >
        {userInfo && <Sidebar toggle={toggle} isOpen={isOpen} />}

        <Stack
          w={'full'}
          minH={'100vh'}
          color={'#030303'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          position={'relative'}
          gap={0}
        >
          {userInfo && <Meniu toggle={toggle} />}
          {!userInfo && <Navbar />}
          <Stack
            w={'full'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            minH={userInfo ? 'calc(100vh - 200px)' : '100vh'}
            zIndex={1}
          >
            <Routes>
              <Route path="/logare" element={<Login api={api} />} />

              {/* Payment steps */}
              <Route path="/register" element={<Register api={api} />} />
              <Route
                path="/register/date-facturare"
                element={
                  <UserRoute>
                    <BillingData api={api} />
                  </UserRoute>
                }
              />
              <Route
                path="/register/pachete"
                element={
                  <UserRoute>
                    <PacheteReg api={api} />
                  </UserRoute>
                }
              />

              <Route
                path="/register/publicatii"
                element={
                  <UserRoute>
                    <ZiareReg api={api} />
                  </UserRoute>
                }
              />
              {/* end of payment steps */}
              {/* user-routes */}
              <Route
                path="/"
                element={
                  <UserRoute>
                    <PayedRoute>
                      <Home api={api} />
                    </PayedRoute>
                  </UserRoute>
                }
              />
              <Route
                path="/articol-nou"
                element={
                  <UserRoute>
                    <PayedRoute>
                      <CreazaArticol api={api} />
                    </PayedRoute>
                  </UserRoute>
                }
              />
              <Route
                path="/pachete"
                element={
                  <UserRoute>
                    <Pachete api={api} />
                  </UserRoute>
                }
              />
              <Route
                path="/plata"
                element={
                  <UserRoute>
                    <PaymentSuccess api={api} />
                  </UserRoute>
                }
              />
              <Route
                path="/user/articol/:id"
                element={
                  <UserRoute>
                    <PayedRoute>
                      <EditareArticol api={api} />
                    </PayedRoute>
                  </UserRoute>
                }
              />
              <Route
                path="/user/publicatii"
                element={
                  <UserRoute>
                    <PayedRoute>
                      <ZiareUser api={api} />
                    </PayedRoute>
                  </UserRoute>
                }
              />
              <Route
                path="/publicatii"
                element={
                  <UserRoute>
                    <PayedRoute>
                      <Ziare api={api} />
                    </PayedRoute>
                  </UserRoute>
                }
              />

              <Route
                path="/user/articole"
                element={
                  <UserRoute>
                    <PayedRoute>
                      <ListaArticole api={api} />
                    </PayedRoute>
                  </UserRoute>
                }
              />
              <Route
                path="/user/setari"
                element={
                  <UserRoute>
                    <PayedRoute>
                      <ProfilUser api={api} />
                    </PayedRoute>
                  </UserRoute>
                }
              />

              {/* admin-routes */}
              <Route
                path="/admin/articol-nou/:id"
                element={
                  <AdminRoute>
                    <ArticolNou api={api} />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/editare/:id"
                element={
                  <AdminRoute>
                    <EditArticol api={api} />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/articole"
                element={
                  <AdminRoute>
                    <ListaArticoleAdmin api={api} />
                  </AdminRoute>
                }
              />

              <Route
                path="/admin/lista-utilizatori"
                element={
                  <AdminRoute>
                    <ListaUtilizatori api={api} />
                  </AdminRoute>
                }
              />
            </Routes>
          </Stack>
        </Stack>
      </HStack>
    </Router>
  );
}

export default App;
