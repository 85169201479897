import {
  Button,
  HStack,
  Stack,
  Switch,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  chakra,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { PageTitle } from '../components';
import axios from 'axios';
import { getError } from '../utils/Utils';
import { TiTick } from 'react-icons/ti';
import { Store } from '../utils/Store';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_USERINFO':
      return {
        ...state,
        loading: false,
        limitaArticole: action.payload.limitaArticole,
      };
    case 'FETCH_BASIC':
      return {
        ...state,
        loading: false,
        basic: action.payload,
      };
    case 'FETCH_STANDARD':
      return {
        ...state,
        loading: false,
        standard: action.payload,
      };
    case 'FETCH_PREMIUM':
      return {
        ...state,
        loading: false,
        premium: action.payload,
      };
    case 'FETCH_ONETIME':
      return {
        ...state,
        loading: false,
        onetime: action.payload,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const Pachete = ({ api }) => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ basic, standard, premium, onetime }, dispatch] = useReducer(
    reducer,
    {
      loading: false,
      error: '',
      basic: [],
      standard: [],
      premium: [],
      onetime: [],
    }
  );
  const [isYearly, setIsYearly] = useState(false);

  useEffect(() => {
    const fetchBasic = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`${api}/api/packs/basic`);
        dispatch({ type: 'FETCH_BASIC', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };

    const fetchStandard = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`${api}/api/packs/standard`);
        dispatch({ type: 'FETCH_STANDARD', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };

    const fetchPremium = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`${api}/api/packs/premium`);
        dispatch({ type: 'FETCH_PREMIUM', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };

    const fetchOnetime = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`${api}/api/packs/onetimepay`);
        dispatch({ type: 'FETCH_ONETIME', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };

    fetchBasic();
    fetchStandard();
    fetchPremium();
    fetchOnetime();
  }, [api]);

  // get today's date area
  const [dataStart, setDataStart] = useState('');
  const [dataEnd, setDataEnd] = useState('');

  useEffect(() => {
    const getToday = () => {
      var day = new Date().getDate();
      var month = new Date().getMonth() + 1;
      var year = new Date().getFullYear();
      var endMonth = month + 1;

      if (month === 12) {
        endMonth = 1;
      }

      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
        endMonth = '0' + endMonth;
      }
      const startDate = day + '/' + month + '/' + year;
      const endDate = isYearly
        ? day + '/' + endMonth + '/' + (year + 1)
        : day + '/' + endMonth + '/' + year;
      setDataStart(startDate);
      setDataEnd(endDate);
    };
    getToday();
  }, [isYearly]);
  // button handlers area

  const handleOneTime = async (item) => {
    const { data } = await axios.post(
      `${api}/api/stripe/create-checkout-session`,
      { orderItems: item, mode: 'payment' },
      { headers: { authorization: `Bearer ${userInfo.token}` } }
    );

    localStorage.setItem(
      'userPack',
      JSON.stringify({
        isPaid: true,
        limitaArticole: item.nrArticole,
        limitaZiare: item.nrZiare,
        dataStart: dataStart,
        dataEnd: dataEnd,
        oneTime: true,
        counterArticole: 0,
      })
    );
    window.open(data.url, '_self');
  };

  const handlePayment = async (item) => {
    const { data } = await axios.post(
      `${api}/api/stripe/create-checkout-session`,
      { orderItems: item, mode: 'subscription', isYearly: isYearly },
      { headers: { authorization: `Bearer ${userInfo.token}` } }
    );

    localStorage.setItem(
      'userPack',
      JSON.stringify({
        isPaid: true,
        limitaArticole: isYearly ? item.nrArticole * 12 : item.nrArticole,
        limitaZiare: item.nrZiare,
        dataStart: dataStart,
        dataEnd: dataEnd,
        isYearly: isYearly,
      })
    );
    window.open(data.url, '_self');
  };

  return (
    <Stack
      className="packPage"
      w={'full'}
      px={{ base: '1rem', lg: '3rem' }}
      pb={'2rem'}
      minH={'100vh'}
      gap={'4rem'}
      alignItems={'center'}
    >
      <PageTitle title={'Alege-ți abonamentul VOX Press'} />
      <Stack
        w={'full'}
        maxW={'80rem'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'2rem'}
      >
        <HStack
          w={'full'}
          gap={'2rem'}
          justifyContent={'space-between'}
          alignItems={{ base: 'center', lg: 'flex-start' }}
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Stack
            w={'300px'}
            fontSize={'1.25rem'}
            alignItems={{ base: 'center', lg: 'flex-start' }}
          >
            <Text>
              Cumpără anual, iar primele <br />
              <strong>două luni</strong> sunt <strong>gratuite</strong>
            </Text>
            <HStack>
              <Text>Lunar</Text>
              <Switch
                border={'1px solid #000'}
                borderRadius={'1rem'}
                size={'lg'}
                colorScheme={'purple'}
                value={isYearly}
                onChange={() => setIsYearly((prev) => !prev)}
              />
              <Text>Anual</Text>
            </HStack>
          </Stack>
          <Card
            direction={{ base: 'column', lg: 'row' }}
            alignItems={'center'}
            w={'fit-content'}
            boxShadow={'0 0 1rem rgba(0,0,0,0.3)'}
          >
            <CardHeader>
              <Heading m={0} as={'h2'} fontSize={'1.5rem'} textAlign={'center'}>
                One time
              </Heading>
            </CardHeader>
            <CardBody>
              <Stack gap={'1rem'}>
                {onetime.map((item) => (
                  <HStack
                    key={item._id}
                    gap={'2.5rem'}
                    flexWrap={'wrap'}
                    justifyContent={'center'}
                  >
                    <HStack
                      w={{ base: 'full', lg: 'fit-content' }}
                      gap={'2.5rem'}
                      justifyContent={'center'}
                    >
                      <Text
                        w={'55px'}
                        fontSize={'1.25rem'}
                        textAlign={'center'}
                        fontWeight={'bold'}
                        position={'relative'}
                      >
                        <chakra.span
                          fontSize={'12px'}
                          position={'absolute'}
                          transform={'rotate(10deg)'}
                          top={'-10px'}
                          right={0}
                          color={'red.300'}
                          textDecoration={'line-through'}
                        >
                          {item.pretBucata * item.nrZiare}
                        </chakra.span>
                        {item.pretBucata * item.nrZiare * (item.reducere / 100)}{' '}
                        &euro;
                      </Text>
                      <Text>Articole: {item.nrArticole} </Text>
                      <Text>Publicații: {item.nrZiare} </Text>
                    </HStack>{' '}
                    <Button
                      onClick={() => handleOneTime(item)}
                      bg={'brand.300'}
                      color={'brand.100'}
                      _hover={{ bg: 'brand.201' }}
                      w={{ base: '150px', lg: 'fit-content' }}
                    >
                      Alege
                    </Button>
                  </HStack>
                ))}
              </Stack>
            </CardBody>
            <CardFooter>
              <Accordion w={'full'} allowToggle>
                <AccordionItem>
                  <AccordionButton w={'full'}>
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Mai multe beneficii</Text>
                      <AccordionIcon />
                    </HStack>
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Limita de cuvinte: </Text>
                      <Text>6000</Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel pb={4}>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Publicare în timp real</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel pb={4}>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Editare în timp real</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel pb={4}>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text> SEO optimizat</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Suport și asistență</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </CardFooter>
          </Card>
        </HStack>

        <HStack
          w={'full'}
          justifyContent={'space-between'}
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        >
          <Card
            w={'350px'}
            minH={'500px'}
            boxShadow={'0 0 1rem rgba(0,0,0,0.3)'}
          >
            <CardHeader>
              <Heading as={'h2'} fontSize={'1.5rem'} textAlign={'center'}>
                Pachetele Basic
              </Heading>
            </CardHeader>
            <CardBody>
              <HStack
                w={'full'}
                h={'full'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                {basic.map((item) => (
                  <Stack key={item._id} gap={'2.5rem'}>
                    <Stack>
                      <Heading as={'h3'} fontSize={'1rem'} textAlign={'center'}>
                        {item.nume}
                      </Heading>
                      <Button
                        onClick={() => handlePayment(item)}
                        bg={item.nume === 'Basic 5' ? 'brand.201' : 'brand.300'}
                        color={'brand.100'}
                        _hover={'none'}
                      >
                        Alege
                      </Button>
                    </Stack>
                    <Text
                      fontSize={'1.25rem'}
                      textAlign={'center'}
                      fontWeight={'bold'}
                      position={'relative'}
                    >
                      <chakra.span
                        fontSize={'16px'}
                        position={'absolute'}
                        top={'-10px'}
                        right={0}
                        color={'red.300'}
                        textDecoration={'line-through'}
                        transform={'rotate(10deg)'}
                      >
                        {isYearly ? item.pret * 10 : item.pret}
                      </chakra.span>
                      {isYearly
                        ? item.pret * (item.reducere / 100) * 10
                        : item.pret * (item.reducere / 100)}{' '}
                      &euro;
                    </Text>
                    <Text>Publicații: {item.nrZiare} </Text>
                    <Text>Articole: {item.nrArticole} </Text>
                  </Stack>
                ))}
              </HStack>
            </CardBody>
            <CardFooter>
              <Accordion w={'full'} allowToggle>
                <AccordionItem>
                  <AccordionButton w={'full'}>
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Mai multe beneficii</Text>
                      <AccordionIcon />
                    </HStack>
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Limita de cuvinte: </Text>
                      <Text>6000</Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel pb={4}>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Publicare în timp real</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel pb={4}>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Editare în timp real</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel pb={4}>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text> SEO optimizat</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Suport și asistență</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </CardFooter>
          </Card>
          <Card
            w={'350px'}
            minH={'550px'}
            boxShadow={'0 0 1rem rgba(0,0,0,0.3)'}
          >
            <CardHeader>
              <Heading as={'h2'} fontSize={'1.5rem'} textAlign={'center'}>
                Pachetele Standard
              </Heading>
            </CardHeader>
            <CardBody>
              <HStack
                w={'full'}
                h={'full'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                {standard.map((item) => (
                  <Stack key={item._id} gap={'2.5rem'}>
                    <Stack>
                      <Heading as={'h3'} fontSize={'1rem'} textAlign={'center'}>
                        {item.nume}
                      </Heading>
                      <Button
                        onClick={() => handlePayment(item)}
                        bg={
                          item.nume === 'Standard 5' ? 'brand.201' : 'brand.300'
                        }
                        color={'brand.100'}
                        _hover={'none'}
                      >
                        Alege
                      </Button>
                    </Stack>
                    <Text
                      fontSize={'1.25rem'}
                      textAlign={'center'}
                      fontWeight={'bold'}
                      position={'relative'}
                    >
                      <chakra.span
                        fontSize={'16px'}
                        position={'absolute'}
                        top={'-10px'}
                        right={0}
                        color={'red.300'}
                        textDecoration={'line-through'}
                        transform={'rotate(10deg)'}
                      >
                        {isYearly ? item.pret * 10 : item.pret}
                      </chakra.span>
                      {isYearly
                        ? item.pret * (item.reducere / 100) * 10
                        : item.pret * (item.reducere / 100)}{' '}
                      &euro;
                    </Text>
                    <Text>Publicații: {item.nrZiare} </Text>
                    <Text>Articole: {item.nrArticole} </Text>
                  </Stack>
                ))}
              </HStack>
            </CardBody>
            <CardFooter>
              <Accordion w={'full'} allowToggle>
                <AccordionItem>
                  <AccordionButton w={'full'}>
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Mai multe beneficii</Text>
                      <AccordionIcon />
                    </HStack>
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Limita de cuvinte: </Text>
                      <Text>6000</Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel pb={4}>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Publicare în timp real</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel pb={4}>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Editare în timp real</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel pb={4}>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text> SEO optimizat</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Suport și asistență</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </CardFooter>
          </Card>
          <Card
            w={'350px'}
            minH={'500px'}
            boxShadow={'0 0 1rem rgba(0,0,0,0.3)'}
          >
            <CardHeader>
              <Heading as={'h2'} fontSize={'1.5rem'} textAlign={'center'}>
                Pachetele Premium
              </Heading>
            </CardHeader>
            <CardBody>
              <HStack
                w={'full'}
                h={'full'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                {premium.map((item) => (
                  <Stack key={item._id} gap={'2.5rem'}>
                    <Stack>
                      <Heading as={'h3'} fontSize={'1rem'} textAlign={'center'}>
                        {item.nume}
                      </Heading>
                      <Button
                        onClick={() => handlePayment(item)}
                        bg={
                          item.nume === 'Premium 5' ? 'brand.201' : 'brand.300'
                        }
                        color={'brand.100'}
                        _hover={'none'}
                      >
                        Alege
                      </Button>
                    </Stack>
                    <Text
                      fontSize={'1.25rem'}
                      textAlign={'center'}
                      fontWeight={'bold'}
                      position={'relative'}
                    >
                      <chakra.span
                        fontSize={'16px'}
                        position={'absolute'}
                        top={'-10px'}
                        right={0}
                        color={'red.300'}
                        textDecoration={'line-through'}
                        transform={'rotate(10deg)'}
                      >
                        {isYearly ? item.pret * 10 : item.pret}
                      </chakra.span>
                      {isYearly
                        ? item.pret * (item.reducere / 100) * 10
                        : item.pret * (item.reducere / 100)}{' '}
                      &euro;
                    </Text>
                    <Text>Publicații: {item.nrZiare} </Text>
                    <Text>Articole: {item.nrArticole} </Text>
                  </Stack>
                ))}
              </HStack>
            </CardBody>
            <CardFooter>
              <Accordion w={'full'} allowToggle>
                <AccordionItem>
                  <AccordionButton w={'full'}>
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Mai multe beneficii</Text>
                      <AccordionIcon />
                    </HStack>
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Limita de cuvinte: </Text>
                      <Text>6000</Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel pb={4}>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Publicare în timp real</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel pb={4}>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Editare în timp real</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel pb={4}>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text> SEO optimizat</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                  <AccordionPanel>
                    {' '}
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Text>Suport și asistență</Text>
                      <Text color={'green.500'} fontSize={'1.5rem'}>
                        <TiTick />
                      </Text>
                    </HStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </CardFooter>
          </Card>
        </HStack>
      </Stack>
    </Stack>
  );
};

export default Pachete;
