import {
  Box,
  Button,
  HStack,
  Heading,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useReducer } from 'react';
import { DataBox, MessageBox, PageTitle } from '../components';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import axios from 'axios';
import { Store } from '../utils/Store';
import { getError } from '../utils/Utils';
import { FaRegNewspaper, FaRegClipboard } from 'react-icons/fa6';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_ARTICOLDATA':
      return {
        ...state,
        loading: false,
        articole: action.payload.articole,
        views: action.payload.views,
        nrArticole: action.payload.nrArticole,
      };
    case 'FETCH_USERINFO':
      return {
        ...state,
        limitaArticole: action.payload.limitaArticole,
        dataEnd: action.payload.dataEnd,
        listaZiare: action.payload.listaZiare,
        isAdmin: action.payload.isAdmin,
        counterArticole: action.payload.counterArticole,
      };

    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
const Home = ({ api }) => {
  const [
    {
      counterArticole,
      limitaArticole,
      isAdmin,
      dataEnd,
      listaZiare,
      articole,
      views,
      nrArticole,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: false,
    isAdmin: false,
    error: '',
    articole: [],
    views: 0,
    nrArticole: 0,
    listaZiare: [],
    limitaArticole: 0,
    dataEnd: '',
    counterArticole: 0,
  });

  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`${api}/api/articol/home-data`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_ARTICOLDATA', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    const fetchPackData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`${api}/api/users/userInfo`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_USERINFO', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    const fetchPayStatus = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const status = await axios.get(`${api}/api/stripe/checkPayment`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });

        dispatch({ type: 'FETCH_STATUS', payload: status.data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    fetchPayStatus();
    fetchPackData();
    fetchData();
  }, [api, userInfo.token]);

  return (
    <Stack
      className="Home"
      w={'full'}
      maxW={'100rem'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      minH={'100vh'}
      gap={'4rem'}
      px={{ base: '1rem', lg: '3rem' }}
      pb={'2rem'}
    >
      <PageTitle title={'VOX Press - Partenerul tău în publicitate'} />
      <HStack
        w={'full'}
        gap={'2rem'}
        flexWrap={'wrap'}
        alignItems={'center'}
        justify={'center'}
      >
        {' '}
        <DataBox
          text={'Total articole in presă'}
          value={nrArticole}
          link={'/user/articole'}
          iconTwo={FaRegNewspaper}
        />{' '}
        <DataBox
          text={'Vizualizări Totale'}
          value={views}
          link={false}
          iconTwo={FaEye}
        />{' '}
        <DataBox
          text={'Publicații alese'}
          value={isAdmin ? 'Toate publicatiile' : listaZiare.length}
          link={'/user/publicatii'}
          iconTwo={FaRegClipboard}
        />
      </HStack>
      <HStack
        gap={'2rem'}
        w={'full'}
        maxW={'80rem'}
        flexWrap={'wrap'}
        alignItems={'center'}
        justify={'center'}
      >
        <DataBox
          text={'Articole folosite'}
          value={
            isAdmin ? 'Sky iz dă limit' : `${counterArticole}/${limitaArticole}`
          }
          link={false}
        />

        <DataBox
          text={'Abonamentul va expira pe data'}
          value={
            userInfo.isAdmin ? 'Admin' : dataEnd ? dataEnd : 'Abonament expirat'
          }
        />
      </HStack>
      <Stack w={'full'} maxW={'80rem'} gap={'3rem'}>
        <HStack
          w={'full'}
          justifyContent={'space-between'}
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        >
          <Heading as={'h2'} color={'#030303 '}>
            Articole adăugate recent
          </Heading>
          <Link to={'/user/articole'}>
            <Button bg={'gradient.buton'} _hover={{ transform: 'scale(1.05)' }}>
              Toate articolele
            </Button>
          </Link>
        </HStack>
        {articole.length > 0 ? (
          <TableContainer w={'full'} color={'#030303'}>
            <Table variant={'striped'} colorScheme="brand">
              <Thead>
                <Tr>
                  <Th visibility={'hidden'}>test</Th>
                </Tr>
              </Thead>
              <Tbody>
                {articole.map((articol) => (
                  <Tr key={articol._id}>
                    <Td p={'10px'}>
                      <Image
                        w={'80px'}
                        src={`${articol.pozaCoperta[0].src}`}
                        alt={articol.descriereCoperta}
                      />
                    </Td>
                    <Td>
                      <Text
                        w={'300px'}
                        overflow={'hidden'}
                        textOverflow={'ellipsis'}
                        whiteSpace={'nowrap'}
                      >
                        {articol.titlu}
                      </Text>
                    </Td>
                    <Td>{articol.categorie}</Td>
                    <Td fontWeight={'bold'}>
                      <Box
                        bg={'brand.300'}
                        color={'brand.201'}
                        borderRadius={'1.5rem'}
                        px={'1.25rem'}
                        py={'1rem'}
                        textAlign={'center'}
                      >
                        {articol.views}
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <MessageBox>Momentan nu aveți articole postate</MessageBox>
        )}
      </Stack>
    </Stack>
  );
};

export default Home;
