import { Checkbox, Image, Stack } from '@chakra-ui/react';
import React from 'react';

const CardZiareUser = ({ ziar, onCheck, limita, publicatiiAlese }) => {
  return (
    <Stack
      className="cardZiare"
      margin={0}
      padding={0}
      w={'250px'}
      h={'150px'}
      alignItems={'center'}
      justifyContent={'center'}
      position={'relative'}
      overflow={'hidden'}
      borderRadius={'1.5rem'}
      bg={'brand.200'}
    >
      {' '}
      <Checkbox
        className="ziareRegCheck"
        w={'220px'}
        h={'120px'}
        position={'absolute'}
        top={'1rem'}
        left={'1rem'}
        size={'lg'}
        iconColor="#000"
        _checked={{
          '& .chakra-checkbox__control': {
            bg: 'purple.300',
            borderColor: 'purple.300',
          },
        }}
        isChecked={publicatiiAlese.includes(ziar)}
        onChange={() => onCheck(ziar)}
        isDisabled={
          publicatiiAlese.length >= limita && !publicatiiAlese.includes(ziar)
        }
      ></Checkbox>
      <Image
        w={'150px'}
        h={'110px'}
        objectFit={'contain'}
        src={ziar.poza}
        alt={ziar.nume}
      />
    </Stack>
  );
};

export default CardZiareUser;
