import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { ChakraProvider } from '@chakra-ui/react';
import { StoreProvider } from './utils/Store';
import { HelmetProvider } from 'react-helmet-async';
import { myTheme } from './theme/chakraTheme';
import EditorContextProvider from './components/EditorContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={myTheme}>
      <StoreProvider>
        <HelmetProvider>
          <EditorContextProvider>
            <App />
          </EditorContextProvider>
        </HelmetProvider>
      </StoreProvider>
    </ChakraProvider>
  </React.StrictMode>
);
