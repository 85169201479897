import { Box, HStack } from '@chakra-ui/react';
import React from 'react';

const RegisterSteps = (props) => {
  return (
    <HStack
      w={'full'}
      flexWrap={{ base: 'wrap', lg: 'nowrap' }}
      justifyContent={'space-evenly'}
      alignItems={'flex-end'}
      className="register-steps"
    >
      <Box
        flex={'1'}
        className={props.step1 ? 'active' : ''}
        fontWeight={'bold'}
        textTransform={'uppercase'}
      >
        Date Facturare
      </Box>
      <Box
        flex={'1'}
        className={props.step2 ? 'active' : ''}
        fontWeight={'bold'}
        textTransform={'uppercase'}
      >
        Pachet
      </Box>

      <Box
        flex={'1'}
        className={props.step3 ? 'active' : ''}
        fontWeight={'bold'}
        textTransform={'uppercase'}
      >
        Plata
      </Box>
      <Box
        flex={'1'}
        className={props.step4 ? 'active' : ''}
        fontWeight={'bold'}
        textTransform={'uppercase'}
      >
        Alege ziare
      </Box>
    </HStack>
  );
};

export default RegisterSteps;
