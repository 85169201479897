import { Button, HStack, Heading, Image, Stack, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
  CardZiareUser,
  LoadingBox,
  MessageBox,
  PageTitle,
} from '../../components';
import { Store } from '../../utils/Store';
import axios from 'axios';
import { getError } from '../../utils/Utils';
import { toast } from 'react-toastify';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_PUBLICATII':
      return {
        ...state,
        loadingZiare: false,
        ziare: action.payload,
      };
    case 'FETCH_LIMITE':
      return {
        ...state,
        loadingUser: false,
        limitaZiare: action.payload.limitaZiare,
        listaZiare: action.payload.listaZiare,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

const ZiareUser = ({ api }) => {
  const [{ loadingZiare, loadingUser, error, ziare }, dispatch] = useReducer(
    reducer,
    {
      loading: false,
      error: '',
      ziare: [],
      user: {},
      limitaZiare: 0,
      listaZiare: [],
    }
  );

  const { state } = useContext(Store);
  const { userInfo } = state;
  const [limita, setLimita] = useState(0);
  const [publicatiiAlese, setPublicatiiAlese] = useState([]);
  const [publicatiiLista, setPublicatiiLista] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`${api}/api/ziar`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_PUBLICATII', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };

    fetchData();
  }, [api, userInfo.token]);

  useEffect(() => {
    const fetchUser = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`${api}/api/users/userInfo`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        data.isAdmin ? setLimita(100) : setLimita(data.limitaZiare);
        setPublicatiiLista([...data.listaZiare]);
        dispatch({ type: 'FETCH_LIMITE', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    fetchUser();
  }, [api, userInfo.token]);
  const handleAdd = (item) => {
    if (publicatiiAlese.includes(item)) {
      setPublicatiiAlese(
        publicatiiAlese.filter((publicatie) => publicatie !== item)
      );
    } else {
      if (publicatiiAlese.length < limita) {
        setPublicatiiAlese([...publicatiiAlese, item]);
      } else {
        toast.info('Ai atins limita de publicații');
      }
    }
  };
  const handleSelectAll = () => {
    if (publicatiiAlese.length < limita) {
      const toateZiarele = ziare.map((ziar) => ziar);
      const capacitateValabila = limita - publicatiiAlese.length;
      const ziareAdaugat = toateZiarele.slice(0, capacitateValabila);
      setPublicatiiAlese([...publicatiiAlese, ...ziareAdaugat]);
    }
  };

  const handleDeselectAll = () => {
    setPublicatiiAlese([]);
  };

  const handleFinishSetup = async () => {
    if (publicatiiAlese.length === 0) {
      toast.info('Trebuie sa selectați cel putin o publicație!');
    } else {
      await axios
        .put(
          `${api}/api/users/register/ziare`,
          { publicatiiAlese },
          { headers: { authorization: `Bearer ${userInfo.token}` } }
        )
        .catch((error) => toast.error(error.message));
      toast.success('Publicatii editate cu succes');
      handleDeselectAll();
    }
  };
  return (
    <Stack
      className="publicatiiUser"
      w={'full'}
      px={{ base: '1rem', lg: '3rem' }}
      minH={'100vh'}
      gap={'4rem'}
      alignItems={'center'}
    >
      <PageTitle title="Alege publicațiile care ți se potrivesc" />
      <Stack w={'full'} maxW={'100rem'} gap={'2rem'}>
        <Heading as={'h2'} fontSize={'1.5rem'}>
          Publicații actuale:
        </Heading>
        <HStack flexWrap={'wrap'} gap={'2rem 1rem'}>
          {publicatiiLista.map((item) => (
            <HStack
              w={'300px'}
              h={'50px'}
              key={item._id}
              boxShadow={'0 0 1rem rgba(0,0,0,0.3)'}
              overflow={'hidden'}
              borderRadius={'1rem'}
              bg={'brand.200'}
              px={'2rem'}
              color={'brand.100'}
            >
              <Image
                w={'80px'}
                h={'40px'}
                objectFit={'contain'}
                src={item.poza}
                alt={item.nume}
              />
              <Text>{item.nume}</Text>
            </HStack>
          ))}
        </HStack>
      </Stack>
      {loadingUser ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox status={'error'}>{error}</MessageBox>
      ) : (
        <HStack
          w={'full'}
          maxW={'100rem'}
          fontSize={{ base: '1rem', lg: '1.5rem' }}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
        >
          <HStack gap={'2rem'}>
            {userInfo.isAdmin ? (
              <Text>Poți alege toate publicațiile</Text>
            ) : (
              <Text>
                Poți alege maxim <strong>{limita} </strong>
                publicații
              </Text>
            )}

            <HStack>
              <Button
                w={'fit-content'}
                borderRadius={'1rem'}
                bg={'brand.201'}
                color={'brand.100'}
                _hover={'none'}
                onClick={handleFinishSetup}
              >
                Adaugă publicațiile
              </Button>
              {userInfo.isAdmin ? (
                <Text>
                  {publicatiiAlese.length}/{ziare.length}
                </Text>
              ) : (
                <Text>
                  {publicatiiAlese.length}/{limita}
                </Text>
              )}
            </HStack>
          </HStack>

          <HStack>
            <Button
              borderRadius={'1rem'}
              bg={'gradient.buton'}
              onClick={handleSelectAll}
            >
              Selectează maximul
            </Button>
            <Button
              borderRadius={'1rem'}
              bg={'gradient.buton'}
              onClick={handleDeselectAll}
            >
              Deselectează totul
            </Button>
          </HStack>
        </HStack>
      )}
      {loadingZiare ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox status={'error'}>{error}</MessageBox>
      ) : (
        ziare && (
          <HStack
            w={'full'}
            maxW={'100rem'}
            flexWrap={'wrap'}
            justifyContent={'center'}
            gap={'2rem 1rem'}
          >
            {ziare.map((item) => (
              <CardZiareUser
                key={item._id}
                ziar={item}
                onCheck={handleAdd}
                publicatiiAlese={publicatiiAlese}
                limita={limita}
              />
            ))}
          </HStack>
        )
      )}
    </Stack>
  );
};

export default ZiareUser;
