import { HStack, Stack } from '@chakra-ui/react';
import React, { useContext, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import { PageTitle } from '../components';
import CardZiare from '../components/CardZiare';
import axios from 'axios';
import { getError } from '../utils/Utils';
import { Store } from '../utils/Store';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        ziare: action.payload,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

const Ziare = ({ api }) => {
  const [{ ziare }, dispatch] = useReducer(reducer, {
    loading: false,
    error: '',
    ziare: [],
  });

  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`${api}/api/ziar`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };

    fetchData();
  }, [api, userInfo.token]);
  return (
    <Stack
      gap={'3rem'}
      justifyContent={'center'}
      alignItems={'center'}
      w={'full'}
      px={{ base: '1rem', lg: '3rem' }}
      py={'2rem'}
    >
      <Helmet>
        <title>VOX Press - Publicații partenere</title>
      </Helmet>
      <PageTitle title={'Publicații partenere'} />
      <HStack
        w={'full'}
        maxW={'80rem'}
        flexWrap={'wrap'}
        justifyContent={'center'}
        gap={'2rem 1rem'}
      >
        {ziare.map((item) => (
          <CardZiare
            key={item._id}
            text={item.nume}
            link={item.link}
            image={item.poza}
          />
        ))}
      </HStack>
    </Stack>
  );
};

export default Ziare;
