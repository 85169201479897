import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  Link as LinkC,
  chakra,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { Store } from '../utils/Store';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../utils/Utils';

const Login = ({ api }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${api}/api/users/login`, {
        email,
        password,
      });
      ctxDispatch({ type: 'USER_SIGNIN', payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
      navigate('/');
    } catch (err) {
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    if (userInfo) {
      navigate('/');
    }
  }, [navigate, userInfo]);

  return (
    <Stack
      w={'full'}
      className="loginStack"
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Helmet>
        <title>Vox Press - Logare</title>
      </Helmet>
      <HStack
        gap={0}
        w={'full'}
        flexDirection={{ base: 'column-reverse', lg: 'row' }}
      >
        <Stack
          display={{ base: 'none', lg: 'flex' }}
          w={{ base: 'full', lg: '60%' }}
          position={'relative'}
          maxH={'100vh'}
        >
          <Box position={'absolute'} inset={0} bg={'rgba(0,0,0,0.65)'}></Box>
          <Image
            w={'100%'}
            h={'100vh'}
            objectFit={'cover'}
            src={'/images/aa.webp'}
            alt={'Login - Gestiune Black Beauty'}
          />
        </Stack>
        <Stack
          color={'brand.201 '}
          h={{ base: '100vh', lg: 'auto' }}
          w={{ base: 'full', lg: '40%' }}
          alignItems={'center'}
          justifyContent={'center'}
          p={{ base: 0, lg: '1rem .5rem' }}
          gap={'2rem'}
        >
          <Stack w={{ base: '300px', lg: '400px' }}>
            <Heading fontSize={'1.75rem'} as={'h1'}>
              Bine ai venit!
            </Heading>
            <Heading fontSize={'1.25rem'} as={'h2'}>
              Accesează-ți contul tău VOX PRESS
            </Heading>
          </Stack>

          <chakra.form
            w={{ base: '300px', lg: '400px' }}
            onSubmit={submitHandler}
            display={'flex'}
            flexDir={'column'}
            gap={'1rem'}
          >
            <FormControl isRequired>
              <FormLabel pl={'1rem'}>Email:</FormLabel>
              <Input
                borderRadius={'2rem'}
                borderColor={'#030303'}
                _hover={'none'}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel pl={'1rem'}>Parola:</FormLabel>
              <Input
                borderRadius={'2rem'}
                borderColor={'#030303'}
                _hover={'none'}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <Stack alignItems={{ base: 'center', lg: 'flex-start' }}>
                <Button
                  borderRadius={'2rem'}
                  type="submit"
                  bg={'linear-gradient(to right, #151616 40%,#414345 110%)'}
                  color={'#fff'}
                  _hover={'none'}
                  _active={'none'}
                >
                  Accesează
                </Button>
              </Stack>
            </FormControl>
          </chakra.form>
          <Stack w={{ base: '300px', lg: '400px' }}>
            <Heading fontSize={'1rem'} as={'h2'}>
              Nu ai cont?
            </Heading>
            <Link to={'/register'}>
              <Heading
                _hover={{ textDecoration: 'underline' }}
                fontSize={'.75rem'}
                as={'h3'}
                textDecor={'underline'}
              >
                Crează-ți un cont aici.
              </Heading>
            </Link>
          </Stack>
          <Stack w={{ base: '300px', lg: '600px' }} alignItems={'center'}>
            <Text>
              Vox Press &#169; All rights reserved. Created by{' '}
              <LinkC href="https://digidev.ro">
                <strong>Digidev Innotech</strong>
              </LinkC>
            </Text>
          </Stack>
        </Stack>
      </HStack>
    </Stack>
  );
};

export default Login;
