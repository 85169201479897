import { Heading, Image, Link, Stack } from '@chakra-ui/react';
import React from 'react';

const CardZiare = (props) => {
  const { image, text, link } = props;

  return (
    <Stack
      className="cardZiare"
      margin={0}
      padding={0}
      w={'250px'}
      h={'150px'}
      alignItems={'center'}
      justifyContent={'center'}
      position={'relative'}
      overflow={'hidden'}
      borderRadius={'1.5rem'}
      bg={'brand.200'}
      p={'2rem'}
    >
      <Link href={link} target="_blank" aria-label={text} rel="noreferrer">
        <Image
          w={'230px'}
          h={'130px'}
          src={image}
          alt={text}
          objectFit={'contain'}
          transition={'all .8s ease-in-out'}
        />
        <Stack
          className="coverCardZiare"
          alignItems={'center'}
          justifyContent={'center'}
          position={'absolute'}
          inset={0}
          bg={'rgba(0,0,0,0.6)'}
          w={'full'}
          zIndex={10}
        >
          <Heading fontSize={'1.25rem'} as={'h2'} color={'brand.100'}>
            {text}
          </Heading>
        </Stack>
      </Link>
    </Stack>
  );
};

export default CardZiare;
