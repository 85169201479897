import {
  Box,
  HStack,
  Image,
  Link,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import { Link as LinkDom, useLocation } from 'react-router-dom';
const Navbar = ({ toggle }) => {
  const location = useLocation();
  const { pathname } = location;
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <Stack
      className="navbar"
      position={'absolute'}
      top={'10px'}
      w={{ base: '350px', lg: '50%' }}
      h={'60px'}
      zIndex={1000}
      bg={{ base: 'rgba(0, 0, 0, 0.8)', lg: 'rgba(0, 0, 0, 0.5)' }}
      borderRadius={'2.5rem'}
      border={'1px solid #979797'}
      backdropFilter={'blur(4px)'}
      alignItems={'center'}
      justifyContent={'center'}
      color={'brand.100'}
      fontWeight={'bold'}
    >
      <HStack
        px={'2rem'}
        w={'full'}
        justifyContent={{ base: 'center', lg: 'space-between' }}
      >
        <Image src={'/images/Logo.png'} alt={'Vox Press'} w={'100px'} />
        {isDesktop ? (
          <HStack
            textTransform={'uppercase'}
            letterSpacing={'.5px'}
            gap={'2rem'}
          >
            <Link
              target="_blank"
              aria-label="Peak & Go"
              rel="noreferrer"
              href="https://voxpress.ro"
            >
              despre noi
            </Link>

            {pathname === '/logare' && (
              <LinkDom to="/register">
                <Box
                  p={'8px 16px'}
                  borderRadius={'2rem'}
                  color={'brand.100'}
                  bg={'linear-gradient(to right, #151616 40%,#414345 110%)'}
                >
                  crează-ți contul
                </Box>
              </LinkDom>
            )}
          </HStack>
        ) : null}
      </HStack>
    </Stack>
  );
};

export default Navbar;
