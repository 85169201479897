import {
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Stack,
  chakra,
  Button,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Store } from '../../utils/Store';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getError } from '../../utils/Utils';

const PjForm = ({ listaJudete, api }) => {
  const navigate = useNavigate();
  const [adresa, setAdresa] = useState('');
  const [oras, setOras] = useState('');
  const [judet, setJudet] = useState('');
  const [numeFirma, setNumeFirma] = useState('');
  const [cui, setCui] = useState('');
  const [nrc, setNrc] = useState('');
  const [banca, setBanca] = useState('');
  const [iban, setIban] = useState('');

  const [listaOrase, setListaOrase] = useState([]);

  const { state } = useContext(Store);
  const { userInfo } = state;

  const oraseHandler = (item) => {
    setJudet(item.nume);
    const oraseRomania = async () => {
      const { data } = await axios.get(
        `https://roloca.coldfuse.io/orase/${item.auto}`
      );
      setListaOrase(data);
    };
    oraseRomania();
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    navigate('/register/pachete');
    try {
      const { data } = await axios.put(
        `${api}/api/users/date-facturare-pj`,
        {
          tipFacturare: 'pj',
          numeFirma,
          cui,
          banca,
          nrc,
          iban,
          judet,
          oras,
          adresa,
        },
        { headers: { authorization: `Bearer ${userInfo.token}` } }
      );
      console.log('send data to user info nu ajunge aici');
      console.log(data);
    } catch (error) {
      toast.error(getError(error));
    }
  };
  return (
    <Stack w={'full'} maxW={'44rem'} pb={'2rem'}>
      <chakra.form onSubmit={submitHandler}>
        <Stack gap={'1rem'} mb={'2rem'}>
          <Heading as={'h3'} fontSize={'1.25rem'}>
            Detalii companie
          </Heading>
          <FormControl>
            <FormLabel>Nume firma:</FormLabel>
            <Input
              borderRadius={'.5rem'}
              borderColor={'#030303'}
              _hover={'none'}
              type="text"
              onChange={(e) => setNumeFirma(e.target.value)}
            />
          </FormControl>
          <HStack alignItems={'flex-end'}>
            <FormControl>
              <FormLabel>Cod unic de inregistrare (CUI):</FormLabel>
              <Input
                borderRadius={'.5rem'}
                borderColor={'#030303'}
                _hover={'none'}
                type="text"
                onChange={(e) => setCui(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                Numar de inregistrare in Registrul Comertului:
              </FormLabel>
              <Input
                borderRadius={'.5rem'}
                borderColor={'#030303'}
                _hover={'none'}
                type="text"
                onChange={(e) => setNrc(e.target.value)}
              />
            </FormControl>
          </HStack>

          <HStack>
            <FormControl>
              <FormLabel>Banca:</FormLabel>
              <Input
                borderRadius={'.5rem'}
                borderColor={'#030303'}
                _hover={'none'}
                type="text"
                onChange={(e) => setBanca(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Cont IBAN:</FormLabel>
              <Input
                borderRadius={'.5rem'}
                borderColor={'#030303'}
                _hover={'none'}
                type="text"
                onChange={(e) => setIban(e.target.value)}
              />
            </FormControl>
          </HStack>
        </Stack>
        {/* gap row */}
        <Stack gap={'1rem'} mb={'2rem'}>
          <Heading as={'h3'} fontSize={'1.25rem'}>
            Sediul social
          </Heading>
          <HStack>
            {' '}
            <FormControl>
              <FormLabel>Judet:</FormLabel>
              <Select
                borderRadius={'.5rem'}
                borderColor={'#030303'}
                _hover={'none'}
                placeholder="Alegeti judetul..."
                onChange={(e) => {
                  const selectedItem = listaJudete.find(
                    (item) => item.nume === e.target.value
                  );
                  oraseHandler(selectedItem);
                }}
              >
                {listaJudete.map((item, index) => (
                  <option key={index} value={item.nume}>
                    {item.nume}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Localitatea:</FormLabel>
              <Select
                borderRadius={'.5rem'}
                borderColor={'#030303'}
                _hover={'none'}
                placeholder="Alegeti orasul..."
                isDisabled={judet === ''}
                onChange={(e) => setOras(e.target.value)}
              >
                {listaOrase.map((item, index) => (
                  <option key={index} value={item.nume}>
                    {item.nume}
                  </option>
                ))}
              </Select>
            </FormControl>
          </HStack>
          <FormControl>
            <FormLabel>Adresa:</FormLabel>
            <Input
              borderRadius={'.5rem'}
              borderColor={'#030303'}
              _hover={'none'}
              type="text"
              onChange={(e) => setAdresa(e.target.value)}
            />
          </FormControl>
        </Stack>
        <Button
          w={'fit-content'}
          borderRadius={'2rem'}
          type="submit"
          bg={'brand.201'}
          color={'#fff'}
          _hover={'none'}
          _active={'none'}
        >
          Continua
        </Button>
      </chakra.form>
    </Stack>
  );
};

export default PjForm;
