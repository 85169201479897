import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Store } from '../utils/Store';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../utils/Utils';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  Link as LinkC,
  chakra,
  Checkbox,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';

const Register = ({ api }) => {
  const navigate = useNavigate();
  const [nume, setNume] = useState('');
  const [prenume, setPrenume] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      if (confirmPassword !== password) {
        toast.error('Parolele nu sunt identice.');
      } else {
        const { data } = await axios.post(`${api}/api/users/register`, {
          nume,
          prenume,
          email,
          password,
        });
        ctxDispatch({ type: 'USER_SIGNIN', payload: data });
        localStorage.setItem('userInfo', JSON.stringify(data));
        navigate('/register/date-facturare');
      }
    } catch (err) {
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    if (userInfo) {
      navigate('/');
    }
  }, [navigate, userInfo]);
  return (
    <Stack
      w={'full'}
      className="registerStack"
      justifyContent={'center'}
      alignItems={'center'}
      pt={{ base: '4rem', md: 0 }}
    >
      <Helmet>
        <title>Vox Press - Înregistrare</title>
      </Helmet>
      <HStack
        gap={0}
        w={'full'}
        flexDirection={{ base: 'column-reverse', lg: 'row' }}
      >
        <Stack
          display={{ base: 'none', lg: 'flex' }}
          w={{ base: 'full', lg: '60%' }}
          position={'relative'}
          maxH={'100vh'}
        >
          <Box position={'absolute'} inset={0} bg={'rgba(0,0,0,0.65)'}></Box>
          <Image
            w={'100%'}
            h={'100vh'}
            objectFit={'cover'}
            src={'/images/aa.webp'}
            alt={'Login - Gestiune Black Beauty'}
          />
        </Stack>
        <Stack
          color={'brand.201'}
          w={{ base: 'full', lg: '40%' }}
          alignItems={'center'}
          justifyContent={'center'}
          p={{ base: 0, lg: '1rem .5rem' }}
          gap={'2rem'}
          py={{ base: '2rem', lg: 0 }}
        >
          <Stack
            w={{ base: '300px', lg: '400px' }}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            <Heading fontSize={'1.75rem'} as={'h1'}>
              Bine ai venit!
            </Heading>
            <Heading fontSize={'1.25rem'} as={'h2'}>
              Crează contul tău Vox Press
            </Heading>
          </Stack>

          <chakra.form
            w={{ base: '300px', lg: '400px' }}
            onSubmit={submitHandler}
            display={'flex'}
            flexDir={'column'}
            gap={'1rem'}
          >
            <FormControl isRequired>
              <FormLabel pl={'1rem'}>Nume:</FormLabel>
              <Input
                borderRadius={'2rem'}
                borderColor={'#030303'}
                _hover={'none'}
                type="text"
                onChange={(e) => setNume(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel pl={'1rem'}>Prenume:</FormLabel>
              <Input
                borderRadius={'2rem'}
                borderColor={'#030303'}
                _hover={'none'}
                type="text"
                onChange={(e) => setPrenume(e.target.value)}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel pl={'1rem'}>Email:</FormLabel>
              <Input
                borderRadius={'2rem'}
                borderColor={'#030303'}
                _hover={'none'}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel pl={'1rem'}>Parola:</FormLabel>
              <Input
                borderRadius={'2rem'}
                borderColor={'#030303'}
                _hover={'none'}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel pl={'1rem'}>Confirmă parola:</FormLabel>
              <Input
                borderRadius={'2rem'}
                borderColor={'#030303'}
                _hover={'none'}
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <Stack alignItems={{ base: 'center', lg: 'flex-start' }}>
                <Checkbox isRequired borderColor={'brand.300'}>
                  Sunt de accord cu{' '}
                  <LinkC
                    href="https://voxpress.ro/gdpr"
                    textDecor={'underline'}
                    color={'blue'}
                  >
                    Politica de Confidențialitate
                  </LinkC>
                </Checkbox>
                <Checkbox isRequired borderColor={'brand.300'}>
                  {' '}
                  Sunt de accord cu{' '}
                  <LinkC
                    href="https://voxpress.ro/termeni-conditii"
                    textDecor={'underline'}
                    color={'blue'}
                  >
                    Termenii și Condițiile
                  </LinkC>
                </Checkbox>
                <Button
                  borderRadius={'2rem'}
                  type="submit"
                  bg={'linear-gradient(to right, #151616 40%,#414345 110%)'}
                  color={'#fff'}
                  _hover={'none'}
                  _active={'none'}
                >
                  Crează contul
                </Button>
              </Stack>
            </FormControl>
          </chakra.form>
          <HStack
            flexWrap={'wrap'}
            w={'full'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Stack
              w={{ base: '300px', lg: 'fit-content' }}
              textAlign={{ base: 'center', lg: 'left' }}
            >
              <Heading fontSize={'1rem'} as={'h2'}>
                Ai deja cont?
              </Heading>
              <Link to={'/logare'}>
                <Heading
                  _hover={{ textDecoration: 'underline' }}
                  fontSize={'.75rem'}
                  as={'h3'}
                  textDecoration={'underline'}
                >
                  Accesează-ți contul aici.
                </Heading>
              </Link>
            </Stack>
            <Stack
              w={{ base: '300px', lg: 'fit-content' }}
              alignItems={'center'}
              fontSize={'.8rem'}
            >
              <Text textAlign={{ base: 'center', lg: 'left' }}>
                Vox Press &#169; All rights reserved.
              </Text>
              <Text>
                Created by{' '}
                <LinkC href="https://digidev.ro">
                  <strong>Digidev Innotech</strong>
                </LinkC>
              </Text>
            </Stack>
          </HStack>
        </Stack>
      </HStack>
    </Stack>
  );
};

export default Register;
