import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stack,
  chakra,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Store } from '../../../utils/Store';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../../../utils/Utils';
import { LoadingBox, MessageBox } from '../../../components';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_INFO':
      return { ...state, loading: false, user: action.payload };
    case 'FETCH_ERROR':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_PROFILE_DATA':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_PROFILE_PASS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false, error: action.payload };

    default:
      return state;
  }
};

const InfoCont = ({ api, border }) => {
  const { state } = useContext(Store);
  const { userInfo } = state;

  const [nume, setNume] = useState('');
  const [prenume, setPrenume] = useState('');
  const [telefon, setTelefon] = useState('');
  const [organizatie, setOrganizatie] = useState('');
  const [tara, setTara] = useState('');
  const [judet, setJudet] = useState('');
  const [oras, setOras] = useState('');
  const [codPostal, setCodPostal] = useState('');
  const [adresa, setAdresa] = useState('');
  const [cui, setCui] = useState('');

  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loadingUpdate: false,
    loading: true,
    user: {},
    error: '',
  });

  useEffect(() => {
    const fetchInfo = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`${api}/api/users/date-profil`, {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_INFO', payload: data });
        setNume(data.nume);
        setPrenume(data.prenume);
        setTelefon(data.dateContact.telefon);
        setOrganizatie(data.dateContact.organizatie);
        setTara(data.dateContact.tara);
        setJudet(data.dateContact.judet);
        setOras(data.dateContact.oras);
        setCodPostal(data.dateContact.codPostal);
        setAdresa(data.dateContact.adresa);
        setCui(data.cuiCNP);
      } catch (error) {
        dispatch({ type: 'FETCH_ERROR', payload: error.message });
        toast.error(error);
      }
    };
    fetchInfo();
  }, [api, userInfo.token]);
  const submitHandlerData = async (e) => {
    e.preventDefault();
    dispatch({ type: 'UPDATE_PROFILE_DATA' });
    try {
      const { data } = await axios.put(
        `${api}/api/users/date-profil`,
        {
          nume,
          prenume,
          telefon,
          organizatie,
          tara,
          judet,
          oras,
          codPostal,
          adresa,
          cui,
        },
        { headers: { authorization: `Bearer ${userInfo.token}` } }
      );
      dispatch({ type: 'UPDATE_PROFILE_DATA', payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
      toast.success('Datele au fost actualizate cu succes');
    } catch (error) {
      dispatch({ type: 'UPDATE_FAIL', payload: error.message });
      toast.error(getError(error));
    }
  };
  return (
    <Stack
      id={'dateGenerale'}
      w={'full'}
      borderRadius={'1.5rem'}
      bg={'brand.100'}
      boxShadow={'0 0 32px rgba(0,0,0,0.3)'}
      px={'3rem'}
      py={'2rem'}
      gap={'3rem'}
    >
      <Heading as={'h2'} fontSize={'1.75rem'}>
        Date generale
      </Heading>
      <chakra.form onSubmit={submitHandlerData}>
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox status="error">{error}</MessageBox>
        ) : (
          <Stack gap={'1.5rem'}>
            <HStack flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
              <FormControl isRequired>
                <FormLabel>Nume:</FormLabel>
                <Input
                  borderColor={border}
                  borderRadius={'1rem'}
                  type="text"
                  value={nume}
                  onChange={(e) => setNume(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Prenume:</FormLabel>
                <Input
                  borderColor={border}
                  borderRadius={'1rem'}
                  type="text"
                  value={prenume}
                  onChange={(e) => setPrenume(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>CUI / CNP:</FormLabel>
                <Input
                  borderColor={border}
                  borderRadius={'1rem'}
                  type="text"
                  value={cui}
                  onChange={(e) => setCui(e.target.value)}
                />
              </FormControl>
            </HStack>
            <HStack flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
              <FormControl isRequired>
                <FormLabel>Număr de Telefon:</FormLabel>
                <Input
                  borderColor={border}
                  borderRadius={'1rem'}
                  type="text"
                  value={telefon}
                  onChange={(e) => setTelefon(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Organizație:</FormLabel>
                <Input
                  borderColor={border}
                  borderRadius={'1rem'}
                  type="text"
                  value={organizatie}
                  onChange={(e) => setOrganizatie(e.target.value)}
                />
              </FormControl>
            </HStack>

            <HStack flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
              <FormControl isRequired>
                <FormLabel>Țara:</FormLabel>
                <Input
                  borderColor={border}
                  borderRadius={'1rem'}
                  type="text"
                  value={tara}
                  onChange={(e) => setTara(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Județ:</FormLabel>
                <Input
                  borderColor={border}
                  borderRadius={'1rem'}
                  type="text"
                  value={judet}
                  onChange={(e) => setJudet(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Oraș:</FormLabel>
                <Input
                  borderColor={border}
                  borderRadius={'1rem'}
                  type="text"
                  value={oras}
                  onChange={(e) => setOras(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Cod Poștal:</FormLabel>
                <Input
                  borderColor={border}
                  borderRadius={'1rem'}
                  type="text"
                  value={codPostal}
                  onChange={(e) => setCodPostal(e.target.value)}
                />
              </FormControl>
            </HStack>
            <FormControl isRequired>
              <FormLabel>Adresă:</FormLabel>
              <Input
                borderColor={border}
                borderRadius={'1rem'}
                type="text"
                value={adresa}
                onChange={(e) => setAdresa(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <Button
                bg={'brand.400'}
                borderRadius={'xl'}
                _active={'none'}
                _hover={{ transform: 'translateY(-5px)' }}
                boxShadow={'0px 5px 24px rgba(0, 0, 0, 0.3)'}
                fontWeight={'bold'}
                color={'text'}
                type={'submit'}
              >
                Actualizează datele
              </Button>
            </FormControl>
          </Stack>
        )}
      </chakra.form>
    </Stack>
  );
};

export default InfoCont;
